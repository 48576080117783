import React from 'react'
import {DefaultTitle} from './page-title/DefaultTitle'

export function MenuInner() {
  return (
    <>
    <DefaultTitle />
    </>
  )
}
