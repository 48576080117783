import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../redux/AuthCRUD'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(
            (res: any) => {
              console.log(res)
              if (res?.data?.success === true) {
                setHasErrors(false)
                setLoading(false)
                formik.resetForm()
                setIsSuccess(true)
                setMessage(res?.data?.message)
              } else {
                setIsSuccess(false)
              }
            },
            (err: any) => {
              console.log(err)
              if (err?.response?.data?.success === false) {
                setHasErrors(true)
                setLoading(false)
                setSubmitting(false)
                setMessage(err?.response?.data?.message)
                console.log(err?.response?.data?.message)
                setIsSuccess(false)
              }
            },
          )
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setIsSuccess(false)
            setStatus('The login detail is incorrect')
          })
      }, 100)
    },
  })

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        {/* {isSuccess === false ? ( */}
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>
        {/* ) : (
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Setup New Password</h1>

            <div className="text-gray-400 fw-bold fs-4">
              Already have reset your password ?
              <Link to="/" className="link-primary fw-bolder">
                Sign in here
              </Link>
            </div>
          </div>
        )} */}

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{message}</div>
          </div>
        )}

        {/* end::Title */}

        {/* begin::Form group */}
        {isSuccess === false ? (
          <>
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                Email
              </label>
              <input
                type="email"
                placeholder="Enter Email"
                autoComplete="off"
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  },
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" className="alert">
                      {formik.errors.email}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
              <button
                type="submit"
                id="kt_password_reset_submit"
                className="btn btn-lg btn-primary fw-bolder me-4"
              >
                <span className="indicator-label">Submit</span>
                {loading && (
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_password_reset_form_cancel_button"
                  className="btn btn-lg btn-light-primary fw-bolder"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>{' '}
            </div>
          </>
        ) : (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">
              <i className="fa fa-check"></i>
              {' ' + message}
            </div>
          </div>
        )}
      </form>
    </>
  )
}
