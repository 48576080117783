import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'

import { OTPlogin } from '../redux/AuthCRUD'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toAbsoluteUrl } from '../../../../_jutemplate/helpers/AssetHelpers'
import './auth.css'
import { error } from 'console'


const initialValues = {
    otp: ''
}

const otpSchema = Yup.object().shape({
    otp: Yup.string().required('OTP is required'),

})

const OTP = () => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const location = useLocation()



    const [otpIdetityId, setOtpIdetityId] = useState<string>('')

    // useEffect(() => {
    //     console.log(location.state)
    // }, [])

    const formik = useFormik({
        initialValues,
        validationSchema: otpSchema,
        onSubmit: (values) => {
            // console.log(values.otp)
            setLoading(true)
            OTPlogin(location?.state, values.otp)
                .then(
                    (res: any) => {
                        setLoading(false)
                        if(res?.data?.success){
                            let token = res?.data?.data?.token 
                            dispatch(auth.actions.login(token))
                        } else {
                            toast.error(res?.data?.message, {
                                theme: 'dark',
                            }) 
                        }
                    },
                    (err: any) => {
                        setLoading(false)
                        if (err?.response?.data?.success === false) {
                            toast.error(err.response.data.message, {
                                theme: 'dark',
                            })
                            console.log(err.response.data.message)
                        }
                    },
                )
                .catch((error) => {
                    setLoading(false)
                    console.log(error)
                })

        },
    })

    return (
        <div>
            <ToastContainer />
            <form
                className="form w-100"
                onSubmit={formik.handleSubmit}
                noValidate
                id="kt_login_signin_form"
            >
                {/* begin::Heading */}
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-10">
                        OTP
                    </h1>
                </div>
                {/* begin::Heading */}

                {/* begin::Form group */}
                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">OTP</label>
                    <input
                        placeholder="Enter Your OTP"
                        {...formik.getFieldProps('otp')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            { 'is-invalid': formik.touched.otp && formik.errors.otp },
                            {
                                'is-valid': formik.touched.otp && !formik.errors.otp,
                            },
                        )}
                        type="text"
                        autoComplete="off"
                    />
                    {formik.touched.otp && formik.errors.otp && (
                        <div className="fv-plugins-message-container">
                            <span role="alert" className="error text-danger">{formik.errors.otp}</span>
                        </div>
                    )}
                </div>
                {/* end::Form group */}



                {/* begin::Action */}
                <div className="text-center">
                    <button
                        type="submit"
                        id="kt_sign_in_submit"
                        className="loginbtn w-100 mb-5"
                        // disabled={formik.isSubmitting || !formik.isValid}
                        disabled={loading ? true : false}
                    >
                        {!loading && <span className="indicator-label">Submit</span>}
                        {loading && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Action */}
            </form>
        </div>
    )
}

export default OTP
